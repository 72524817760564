<template>
  <section id="DesarrolloEmpresarial" class="container" dataES.aos="fade-up">
    <div class="w-100 d-flex justify-content-start ms-8">
      <h2>
        <span style="color: #14993c"> Español </span>
      </h2>
    </div>
    <div class="text-center mb-5">
      <input
        class="form-control form-control-lg wet-form fw-bold text-center green__text mb-2"
        type="text"
        v-model="dataES.greenTitle"
      />
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-4"
        type="text"
        v-model="dataES.blackTitle"
      />
      <input
        class="form-control wet-form text-center"
        type="text"
        v-model="dataES.description"
      />
    </div>
    <img
      :src="require(`../../assets/${dataES.img}`)"
      class="graph d-none d-md-block"
      alt="Imagen de grafica de desarrollo empresarial"
    />
    <img
      :src="require(`../../assets/${dataES.imgMobile}`)"
      class="graph d-block d-md-none"
      alt="Imagen de grafica de desarrollo empresarial version movil"
    />

    <button class="btn btn-success btn-lg mt-3 mb-3" @click="syncContent('ES')">
      Guardar
    </button>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="w-100 d-flex justify-content-start ms-8">
      <h2>
        <span style="color: #14993c"> English </span>
      </h2>
    </div>
    <div class="text-center mb-5">
      <input
        class="form-control form-control-lg wet-form fw-bold text-center green__text mb-2"
        type="text"
        v-model="dataEN.greenTitle"
      />
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-4"
        type="text"
        v-model="dataEN.blackTitle"
      />
      <input
        class="form-control wet-form text-center"
        type="text"
        v-model="dataEN.description"
      />
    </div>
    <img
      :src="require(`../../assets/${dataEN.img}`)"
      class="graph d-none d-md-block"
      alt="Imagen de grafica de desarrollo empresarial"
    />
    <img
      :src="require(`../../assets/${dataEN.imgMobile}`)"
      class="graph d-block d-md-none"
      alt="Imagen de grafica de desarrollo empresarial version movil"
    />

    <button class="btn btn-success btn-lg mt-3 mb-3" @click="syncContent('EN')">
      Save
    </button>
  </section>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";

export default {
  name: "DesarrolloEmpresarialAdmin",
  data() {
    return {
      dataES: {
        greenTitle: "Etapas",
        blackTitle: "de Desarrollo Empresarial",
        description:
          "Los Programas Wetforest se configuran con el cliente en función de la etapa de desarrollo empresarial en que se encuentra.",
        img: "img/graph-es.webp",
        imgMobile: "img/graph-mobile-es.webp",
      },

      dataEN: {
        greenTitle: "Etapas",
        blackTitle: "de Desarrollo Empresarial",
        description:
          "Los Programas Wetforest se configuran con el cliente en función de la etapa de desarrollo empresarial en que se encuentra.",
        img: "img/graph-es.webp",
        imgMobile: "img/graph-mobile-es.webp",
      },
    };
  },
  methods: {
    async fetchContent() {
      const section = await getSection("enterpriseDevelopment");

      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "enterpriseDevelopment",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "enterpriseDevelopment",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<style scoped>
.green__text {
  color: #27ba54;
}

.graph {
  width: 100%;
}

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}
</style>
